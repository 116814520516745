const concatClassNames = (
  ...classNames: (string | string[] | undefined | false)[]
) =>
  classNames
    .flatMap((cn) => (Array.isArray(cn) ? cn : [cn]))
    .filter((cn) => cn)
    .join(" ");

const c = concatClassNames;

export default c;
